import { WozContentState } from './interfaces'

const state: WozContentState = {
  documentsLoading: false,
  documents: [],
  hasAnyDocuments: false,
  tableDocuments: [],
  documentsPaginationInfo: {
    kbItemsCount: 0,
    totalPages: 0,
    totalItems: 0,
    hasNextPage: false,
    page: 0
  },
  lastSearchParameters: {
    query: undefined,
    page: 1
  },
  agent: {
    documentsLoading: false,
    documents: [],
    hasAnyDocuments: false,
    tableDocuments: []
  },
  contentGapsList: {
    data: undefined,
    pagination: {
      page: 0,
      totalPages: 0,
      totalItems: 0,
      from: 0,
      to: 0
    }
  }
}

export default state
