import { enums } from '@/modules/Chat/components/sidebar/services/enums'

export const chatListOpenConversations = [
  enums.chatStatus.waiting,
  enums.chatStatus.talking,
  enums.chatStatus.missed
]

export const chatListClosedConversations = [
  enums.chatStatus.closed,
  enums.chatStatus.offline,
  enums.chatStatus.closedMissed
]

export const inboxLists = {
  all: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  byAgent: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  unread: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  mentioned: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  withoutResponsible: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  chatbot: {
    open: [enums.chatStatus.started],
    closed: [...chatListClosedConversations]
  },
  participation: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  timeExceeded: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  },
  group: {
    open: chatListOpenConversations,
    closed: chatListClosedConversations
  }
}
