import { RouteConfig } from 'vue-router'

import {
  ADDITIONALS_ROUTES,
  BILLING_ROUTES,
  BILLING_STORE_ACTIONS,
  BILLING_USERS,
  CHANGE_PLAN_ROUTES
} from '@/modules/Billing/enums'
import { firstBuyRoutesChildren } from '@/modules/Billing/first-buy-routes-children'
import { useStore } from '@/modules/Billing/store'

import store from '@/store'

const routes: RouteConfig[] = [
  {
    path: '/billing',
    component: () => import('@/views/Billing.vue'),
    beforeEnter(to, from, next) {
      if (
        from.name !== 'chat-settings-other-channels-whatsapp-web' &&
        from.name !==
          'chat-settings-whatsapp-oficial-credit-management-buy-credits'
      ) {
        store.dispatch('checkout/clearCheckout')
      }

      useStore().dispatch(BILLING_STORE_ACTIONS.LOAD_INITAL).finally(next)
    },
    children: [
      {
        path: '',
        redirect: {
          name: BILLING_ROUTES.INVOICES
        }
      },
      {
        path: 'data',
        name: BILLING_ROUTES.BILLING_DATA,
        component: () => import('./pages/BillingDataPage.vue')
      },
      {
        path: 'license',
        name: BILLING_ROUTES.LICENSE,
        component: () => import('./pages/LicenseManagementPage.vue')
      },
      {
        path: 'old-license',
        name: 'old-license',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/person/list//'
        }
      },
      {
        path: 'whatsapp/number',
        name: BILLING_ROUTES.WHATSAPP_BUY,
        component: () => import('./pages/BuyWhatsAppOfficialPage.vue')
      },
      {
        path: 'invoices',
        name: BILLING_ROUTES.INVOICES,
        component: () => import('./pages/InvoicePage.vue')
      },
      {
        path: 'cancel',
        name: BILLING_ROUTES.CANCEL,
        component: () => import('./pages/Cancel/CancelPage.vue')
      },
      {
        path: 'change-plan',
        component: () => import('./pages/ChangePlanPage.vue'),
        children: [
          {
            path: '',
            name: CHANGE_PLAN_ROUTES.SELECT_PLAN,
            component: () => import('./pages/ChangePlan/ChoosePlanPage.vue')
          }
        ]
      },
      {
        name: BILLING_ROUTES.CHECKOUT_RESUME,
        path: 'checkout/resume',
        redirect: {
          name: 'octa-store'
        }
      },
      {
        name: BILLING_ROUTES.CHANGE_PAYMENT,
        path: 'checkout/change-method',
        component: () => import('./pages/Checkout/ChangePaymentMethodPage.vue')
      },
      {
        path: 'additionals',
        component: () => import('./pages/BuyAddOn/BuyAddOnPage.vue'),
        children: [
          {
            path: '',
            name: ADDITIONALS_ROUTES.ADDITIONALS,
            component: () =>
              import('./checkout/additionals/AdditionalsPage.vue')
          },
          {
            path: 'summary',
            name: ADDITIONALS_ROUTES.SUMMARY,
            component: () =>
              import('./checkout/purchase-overview/PurchaseOverview.vue')
          },
          {
            path: 'payment',
            name: ADDITIONALS_ROUTES.PAYMENT,
            component: () => import('./pages/BuyAddOn/PaymentPage.vue')
          },
          {
            path: 'approved-payment',
            name: ADDITIONALS_ROUTES.PAYMENT_SUCCESS,
            component: () =>
              import('./pages/Checkout/CheckoutApprovedPayment.vue')
          },
          {
            path: 'success',
            name: ADDITIONALS_ROUTES.CHECKOUT_ADDITIONALS_SUCCESS,
            component: () => import('./pages/BuyAddOn/CheckoutSuccess.vue')
          }
        ]
      },
      {
        path: 'users-management',
        component: () =>
          import('./pages/UsersManagement/UsersManagementPage.vue'),
        children: [
          {
            path: '',
            name: BILLING_USERS.USERS_MANAGEMENT,
            redirect: {
              name: BILLING_USERS.USERS_PREVIEW
            }
          },
          {
            name: BILLING_USERS.USERS_PREVIEW,
            path: 'users-preview',
            component: () =>
              import('./pages/UsersManagement/UsersPreviewPage.vue')
          },
          {
            name: BILLING_USERS.CHECKOUT_PREVIEW,
            path: 'checkout-preview',
            component: () =>
              import('./pages/UsersManagement/PlanPreviewPage.vue')
          },
          {
            name: BILLING_USERS.CHECKOUT_PAYMENT,
            path: 'checkout-payment',
            props: true,
            component: () =>
              import('./pages/UsersManagement/CheckoutPaymentPage.vue')
          },
          {
            name: BILLING_USERS.PAYMENT_SUCCESS,
            path: 'payment-success',
            component: () =>
              import('./pages/Checkout/CheckoutApprovedPayment.vue')
          }
        ]
      },
      firstBuyRoutesChildren
    ]
  }
]

export default routes
