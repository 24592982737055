export enum ADD_CONTENT_TABS {
  FILES = 0,
  URLS = 1
}

export enum ADD_CONTENT_MODAL_STEPS {
  SELECT_CONTENT = 0,
  URL = 1,
  FILES = 2
}

export enum WOZ_MEMORY_SOURCE {
  KnowledgeBase = 'KnowledgeBase',
  Upload = 'Upload',
  WebCrawler = 'WebCrawler'
}
export enum DOCUMENT_STATUS {
  ENQUEUED = 'ENQUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

export enum WOZ_LANDING_PAGE_EVENTS {
  PAGEVIEW = 'woz-landing-page-pageview',
  TRIAL_ACTIVATED = 'woz-landing-page-trial-activated'
}

export enum WOZ_AGENT_EDIT_TAB {
  PROFILE = 0,
  CHAT = 1,
  KNOWLEDGE = 2
}
