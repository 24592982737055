import { PaginatedData } from '@/store/interfaces/pagination'

import {
  WozContentState,
  WozDocument,
  TableWozDocument,
  WozContentPageInformation,
  WozContentGaps
} from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_WOZ_CONTENT_DOCUMENTS_LOADING](
    state: WozContentState,
    loading: boolean
  ) {
    state.documentsLoading = loading
  },
  [types.SET_WOZ_CONTENT_DOCUMENTS](
    state: WozContentState,
    documents: WozDocument[]
  ) {
    state.documents = documents
  },
  [types.SET_HAS_ANY_DOCUMENTS](state: WozContentState, hasDocuments: boolean) {
    state.hasAnyDocuments = hasDocuments
  },
  [types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE](
    state: WozContentState,
    tableDocuments: TableWozDocument[]
  ) {
    state.tableDocuments = tableDocuments
  },
  [types.SET_WOZ_CONTENT_DOCUMENTS_TABLE_PAGINATION_INFO](
    state: WozContentState,
    documentsPaginationInfo: WozContentPageInformation
  ) {
    state.documentsPaginationInfo = documentsPaginationInfo
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING](
    state: WozContentState,
    loading: boolean
  ) {
    state.agent.documentsLoading = loading
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS](
    state: WozContentState,
    documents: WozDocument[]
  ) {
    state.agent.documents = documents
  },
  [types.SET_AGENT_HAS_ANY_DOCUMENTS](
    state: WozContentState,
    hasDocuments: boolean
  ) {
    state.agent.hasAnyDocuments = hasDocuments
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE](
    state: WozContentState,
    tableDocuments: TableWozDocument[]
  ) {
    state.agent.tableDocuments = tableDocuments
  },
  [types.SET_LAST_SEARCH_PARAMETERS](
    state: WozContentState,
    lastSearchParameters: unknown
  ) {
    state.lastSearchParameters = lastSearchParameters
  },
  [types.SET_CONTENT_GAPS_LIST](
    state: WozContentState,
    contentGapsList: PaginatedData<WozContentGaps>
  ) {
    state.contentGapsList = contentGapsList
  },
  [types.SET_CONTENT_GAPS_LIST_SEARCHING](state: WozContentState) {
    state.contentGapsList = { ...state.contentGapsList, data: undefined }
  },
  [types.CLEAR_CONTENT_GAPS_LIST](state: WozContentState) {
    state.contentGapsList = {
      ...state.contentGapsList,
      pagination: { page: 0, totalPages: 0, totalItems: 0, from: 0, to: 0 },
      data: undefined
    }
  }
}
