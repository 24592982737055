import { ActionContext } from 'vuex'

import {
  FilterKeys,
  Filters,
  ProductsColors,
  GeneralFilters,
  GeneralFiltersData
} from '@/modules/ChatSettings/financial-management/credits/credits.enum'
import { CreditsService } from '@/modules/ChatSettings/financial-management/credits/credits.service'

import { RootState } from '@/store/interfaces'

import { Credits, WppNumber, ProductExpenses } from './interfaces'
import * as types from './mutations-types'

const creditsService = CreditsService.getInstance()

export const getProductExpenses = async (
  context: ActionContext<Credits, RootState>
) => {
  context.commit(types.SET_PRODUCT_EXPENSES_ERROR, false)

  context.commit(types.SET_PRODUCT_EXPENSES_LOADER, true)

  try {
    const { productExpensesFilters } = context.getters

    const items: ProductExpenses = await creditsService.getProductExpenses(
      productExpensesFilters[GeneralFilters.Date]
    )

    context.commit(types.SET_PRODUCT_EXPENSES, {
      ...items,
      expenses: items.expenses
        .map(item => ({
          ...item,
          color: ProductsColors?.[item.product] || ProductsColors.DEFAULT
        }))
        .sort((a, b) => a.product.localeCompare(b.product))
    })
  } catch {
    context.commit(types.SET_PRODUCT_EXPENSES_ERROR, true)
  } finally {
    context.commit(types.SET_PRODUCT_EXPENSES_LOADER, false)
  }
}

export const setProductExpensesFilter = async (
  context: ActionContext<Credits, RootState>,
  { key, value }: { key: GeneralFilters; value: any }
) => {
  context.commit(types.SET_PRODUCT_EXPENSES_FILTER, {
    ...context.getters.productExpensesFilters,
    [key]: value
  })

  if (key !== GeneralFilters.Product) {
    await context.dispatch('getProductExpenses')
  }
}

export const resetProductExpensesFilters = async (
  context: ActionContext<Credits, RootState>
) => {
  context.commit(types.SET_PRODUCT_EXPENSES_FILTER, {
    [GeneralFilters.Product]: GeneralFiltersData.All,
    [GeneralFilters.Date]: ''
  })
}

export const getConsumptionEstimation = async (
  context: ActionContext<Credits, RootState>
) => {
  const consumptionEstimation = await creditsService.getConsumptionEstimation()

  context.commit(types.SET_CONSUMPTION_ESTIMATION, consumptionEstimation)
}

export const getDefaultCategorizedReport = async (
  context: ActionContext<Credits, RootState>
) => {
  const categorizedReport = await creditsService.getCategorizedReport({
    product: '',
    phone: '',
    date: {
      startDate: '',
      endDate: ''
    }
  })

  const items: { [key: string]: any } = {}

  const defaultItemsByProduct: { [key: string]: any } = {
    WHATSAPP: {
      [Filters.Phone]: ''
    }
  }

  Object.keys(categorizedReport).forEach(key => {
    items[key] = {
      filters: {
        [Filters.Date]: {
          [FilterKeys.StartDate]: '',
          [FilterKeys.EndDate]: ''
        },
        ...defaultItemsByProduct[key]
      },
      data: categorizedReport[key],
      hasError: false,
      isLoading: false
    }
  })

  context.commit(types.SET_CATEGORIZED_REPORT, items)

  return categorizedReport
}

export const getIntegratorWhatsappNumbers = async (
  context: ActionContext<Credits, RootState>
) => {
  context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS_LOADER, true)

  context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS_ERROR, false)

  try {
    const integratorWhatsappNumbers: WppNumber[] =
      await creditsService.getIntegratorWhatsappNumbers()

    const numbers = integratorWhatsappNumbers.map(
      ({ id, number, integrator, name }) => ({
        id,
        number,
        integrator,
        label: `${name} ${number}`
      })
    )

    context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS, numbers)
  } catch {
    context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS_ERROR, true)
  } finally {
    context.commit(types.SET_INTEGRATOR_WHATSAPP_NUMBERS_LOADER, false)
  }
}

export const setCategorizedReportProductFilter = async (
  context: ActionContext<Credits, RootState>,
  { product, filter, value }: { product: string; filter: string; value: any }
) => {
  const { categorizedReport } = context.getters

  const parsedCategorizedReport = { ...categorizedReport }

  parsedCategorizedReport[product].filters[filter] = value

  parsedCategorizedReport[product].hasError = false

  parsedCategorizedReport[product].isLoading = true

  context.commit(types.SET_CATEGORIZED_REPORT, parsedCategorizedReport)

  try {
    const data = await creditsService.getCategorizedReport({
      ...parsedCategorizedReport[product].filters
    })

    parsedCategorizedReport[product].data = data[product]
    context.commit(types.SET_CATEGORIZED_REPORT, parsedCategorizedReport)
  } catch {
    parsedCategorizedReport[product].hasError = true

    context.commit(types.SET_CATEGORIZED_REPORT, parsedCategorizedReport)
  } finally {
    parsedCategorizedReport[product].isLoading = false

    context.commit(types.SET_CATEGORIZED_REPORT, parsedCategorizedReport)
  }
}
