export const SET_WOZ_CONTENT_DOCUMENTS_LOADING =
  'SET_WOZ_CONTENT_DOCUMENTS_LOADING'

export const SET_WOZ_CONTENT_DOCUMENTS = 'SET_WOZ_CONTENT_DOCUMENTS'

export const SET_HAS_ANY_DOCUMENTS = 'SET_HAS_ANY_DOCUMENTS'

export const SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE =
  'SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING =
  'SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS = 'SET_AGENT_WOZ_CONTENT_DOCUMENTS'

export const SET_AGENT_HAS_ANY_DOCUMENTS = 'SET_AGENT_HAS_ANY_DOCUMENTS'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE =
  'SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE'

export const SET_WOZ_CONTENT_DOCUMENTS_TABLE_PAGINATION_INFO =
  'SET_WOZ_CONTENT_DOCUMENTS_TABLE_PAGINATION_INFO'

export const SET_LAST_SEARCH_PARAMETERS = 'SET_LAST_SEARCH_PARAMETERS'

export const SET_AGENT_LAST_SEARCH_PARAMETERS =
  'SET_AGENT_LAST_SEARCH_PARAMETERS'

export const SET_CONTENT_GAPS_LIST = 'SET_CONTENT_GAPS_LIST'

export const SET_CONTENT_GAPS_LIST_SEARCHING = 'SET_CONTENT_GAPS_LIST_SEARCHING'

export const CLEAR_CONTENT_GAPS_LIST = 'CLEAR_CONTENT_GAPS_LIST'
