import { ActionContext } from 'vuex'

import {
  WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION,
  WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE
} from '@/modules/Woz/consts'
import {
  getDocuments,
  getDocument,
  updateActiveDocument,
  deleteDocument,
  bulkDelete,
  bulkActiveDocument,
  toggleKBDocuments,
  getAgentDocuments,
  linkContentToAgent,
  bulkProcessAgentDocuments,
  unlinkAgentDocuments,
  toggleLinkKBDocumentsAgent,
  updateActiveLinkDocumentAgent,
  getContentGaps,
  markAsResolved
} from '@/modules/Woz/content/content.service'
import { DOCUMENT_STATUS, WOZ_MEMORY_SOURCE } from '@/modules/Woz/enums'

import i18n from '@/common/i18n'

import { RootState } from '@/store/interfaces'

import { TableWozDocument, WozContentState, WozDocument } from './interfaces'
import * as types from './mutation-types'

const PAGE_SIZE = 30

const getTableDocument = (document: any) => {
  return {
    ...document,
    sent: null,
    used: null,
    selected: false,
    childIsOpen: false,
    isChildVisible: false
  }
}

const getTableDocuments = (documents: WozDocument[], paginationInfo: any) => {
  const tableDocuments: TableWozDocument[] = []

  if (paginationInfo.kbItemsCount > 0 && paginationInfo.page === 1) {
    tableDocuments.push(
      getTableDocument({
        id: WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION,
        source: WOZ_MEMORY_SOURCE.KnowledgeBase,
        originalState: 'active',
        title: i18n?.t('woz.content.kb'),
        active: true,
        hasChild: true,
        childrenCount: paginationInfo.kbItemsCount,
        searching: false,
        currentPage: 1,
        totalPages:
          1 + Math.floor((paginationInfo.kbItemsCount - 1) / PAGE_SIZE)
      })
    )
  }

  const mappedDocuments = documents.map((doc: WozDocument) => {
    const state = doc.active ? 'active' : 'inactive'

    const originalState =
      doc.status === DOCUMENT_STATUS.COMPLETED ? state : 'processing'

    const document = getTableDocument({
      ...doc,
      originalState
    })

    if (document.source === WOZ_MEMORY_SOURCE.KnowledgeBase)
      document.parentId = WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION

    tableDocuments.push(document)

    return document
  })

  mappedDocuments
    .filter((document: any) => document.parentId)
    .forEach((document: any) => {
      document.isChild = true

      const parent = tableDocuments.find(d => d.id === document.parentId)

      if (parent) {
        parent.hasChild = true

        parent.childIsOpen = false

        tableDocuments.splice(tableDocuments.indexOf(parent) + 1, 0, document)
      }
    })

  return tableDocuments
}

const removeDuplicates = (arr: any) => {
  const map = new Map()

  arr.forEach((doc: any) => map.set(doc.id, doc))

  return Array.from(map.values())
}

export const getWozDocuments = async (
  context: ActionContext<WozContentState, RootState>,
  {
    query,
    page,
    hideLoader = false,
    replaceCurrentContents = false
  }: {
    query: { [key: string]: any }
    page: number
    hideLoader?: boolean
    replaceCurrentContents?: boolean
  }
) => {
  if (!hideLoader) context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_LOADING, true)

  let response

  const parsedQuery = query
    ? Object.keys(query)
        .filter(key => query[key] !== undefined && query[key] !== '')
        .map(key => `${key}=${query[key]}`)
        .join('&')
    : ''

  try {
    response = await getDocuments(parsedQuery, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on get documents', { ex })

    throw ex
  } finally {
    context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_LOADING, false)
  }

  const { documents, paginationInfo } = response

  if (!parsedQuery && (documents.length || paginationInfo.kbItemsCount)) {
    context.commit(types.SET_HAS_ANY_DOCUMENTS, true)
  }

  const documentsToStash = getDocumentsToStash(documents, page, PAGE_SIZE)

  context.commit(types.SET_WOZ_CONTENT_DOCUMENTS, documentsToStash)

  const tableDocuments = getTableDocuments(documentsToStash, paginationInfo)

  const { tableDocuments: currentTableDocuments } = context.getters

  const documentsToCommit = removeDuplicates(
    replaceCurrentContents
      ? tableDocuments
      : [...currentTableDocuments, ...tableDocuments]
  )

  context.commit(types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE, documentsToCommit)

  context.commit(types.SET_LAST_SEARCH_PARAMETERS, { query, page })

  context.commit(
    types.SET_WOZ_CONTENT_DOCUMENTS_TABLE_PAGINATION_INFO,
    paginationInfo
  )

  return { documents: documentsToCommit, pageInformation: paginationInfo }
}

export const getAgentWozDocuments = async (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    query: string
    page: number
  }
) => {
  const { query, agentId, page } = payload

  context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING, true)

  if (!query) {
    context.commit(types.SET_LAST_SEARCH_PARAMETERS, {
      query: '',
      page: 1,
      agentId: undefined
    })

    context.commit(types.SET_AGENT_HAS_ANY_DOCUMENTS, false)
  }

  let response

  try {
    response = await getAgentDocuments(agentId, query, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on get agent documents', { ex })

    throw ex
  } finally {
    context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING, false)
  }

  const { documents, paginationInfo } = response

  if (!query && (documents.length || paginationInfo.kbItemsCount)) {
    context.commit(types.SET_AGENT_HAS_ANY_DOCUMENTS, true)
  }

  const documentsToStash = getDocumentsToStash(documents, page, PAGE_SIZE)

  context.commit(types.SET_AGENT_WOZ_CONTENT_DOCUMENTS, documentsToStash)

  const tableDocuments = getTableDocuments(
    documentsToStash,
    response.paginationInfo
  )

  context.commit(
    types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
    tableDocuments
  )

  context.commit(types.SET_LAST_SEARCH_PARAMETERS, { query: page, agentId })

  return { documents: tableDocuments, pageInformation: response.paginationInfo }
}

export const getWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  return getDocument(id).catch(ex =>
    console.error('Error on get document', { ex, id })
  )
}

export const updateTableDocumentStatus = (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    id: string[]
    active: boolean
  }
) => {
  const { id, active } = payload

  const { tableDocuments } = context.getters

  context.commit(
    types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
    tableDocuments.map((item: TableWozDocument) => {
      if (id.includes(item.id)) {
        item.active = active

        item.originalState = active ? 'active' : 'inactive'
      }

      return item
    })
  )
}

export const toggleWozDocument = (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    id: string
    active: boolean
  }
) => {
  const { id, active } = payload

  return (
    id === WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION
      ? toggleKBDocuments(active)
      : updateActiveDocument(id, active).then(() => {
          context.dispatch('updateTableDocumentStatus', {
            id: [id],
            active
          })
        })
  ).catch(ex => console.error('Error on enable document', { ex, id }))
}

export const bulkProcessWozDocument = async (
  context: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    activate: boolean
  }
) => {
  try {
    await bulkActiveDocument(payload.ids, payload.activate)

    const { tableDocuments } = context.getters

    const updatedTableDocuments = tableDocuments.map(
      (item: TableWozDocument) => {
        if (payload.ids.includes(item.id)) {
          item.active = payload.activate

          item.originalState = payload.activate ? 'active' : 'inactive'
        }

        return item
      }
    )

    context.commit(
      types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
      updatedTableDocuments
    )
  } catch (ex) {
    console.error('Error on bulkProcessWozDocument', { ex })

    throw ex
  }
}

export const executeDeleteDocument = async (
  context: ActionContext<WozContentState, RootState>,
  id: string
) => {
  try {
    await deleteDocument(id)

    const updatedTableDocuments = context.getters.tableDocuments.filter(
      (item: TableWozDocument) => item.id !== id
    )

    context.commit(
      types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
      updatedTableDocuments
    )
  } catch (ex) {
    console.error('Error on executeDeleteDocument', { ex, id })

    throw ex
  }
}

export const executeBulkDelete = async (
  context: ActionContext<WozContentState, RootState>,
  ids: string[]
) => {
  try {
    await bulkDelete(ids)

    const updatedTableDocuments = context.getters.tableDocuments.filter(
      (item: TableWozDocument) => !ids.includes(item.id)
    )

    context.commit(
      types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE,
      updatedTableDocuments
    )
  } catch (ex) {
    console.error('Error on executeBulkDelete', { ex })

    throw ex
  }
}

const resolveChildrenDocumentsFilter = (parentId: string, query: string) => {
  let filter = `parentId=${parentId}`
  if (parentId === WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION)
    filter = `source=${WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE}`

  if (query) filter += '&' + query

  return filter
}

export const searchChildrenDocuments = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    parentId: string
    query: string
    page: number
  }
) => {
  const { parentId, query, page } = payload

  const filter = resolveChildrenDocumentsFilter(parentId, query)

  try {
    const response = await getDocuments(filter, page, PAGE_SIZE)
    const documents = response.documents
    return getTableDocumentsClient(documents, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on search children documents', { ex, payload })
    throw ex
  }
}

export const searchChildrenAgentDocuments = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    parentId: string
    query: string
    page: number
  }
) => {
  const { agentId, parentId, query, page } = payload

  const filter = resolveChildrenDocumentsFilter(parentId, query)

  try {
    const response = await getAgentDocuments(agentId, filter, page, PAGE_SIZE)

    const documents = response.documents
    return getTableDocumentsClient(documents, page, PAGE_SIZE)
  } catch (ex) {
    console.error('Error on search children agent documents', { ex, payload })
    throw ex
  }
}

const getTableDocumentsClient = (
  documents: any[],
  page: number,
  pageSize: number
) => {
  return getDocumentsToStash(documents, page, pageSize).map(
    (doc: WozDocument) => {
      const activeStatus = doc.active ? 'active' : 'inactive'
      const originalState =
        doc.status === 'COMPLETED' ? activeStatus : 'processing'

      const document = getTableDocument({
        ...doc,
        originalState
      })

      if (document.source === WOZ_MEMORY_SOURCE.KnowledgeBase) {
        document.parentId = WOZ_MEMORY_SOURCE_KNOWLEDGE_BASE_ABBREVIATION
      }

      return document
    }
  )
}

const getDocumentsToStash = (
  documents: any[],
  page: number,
  pageSize: number
): WozDocument[] => {
  return documents.map((document: any) => {
    return {
      id: document.id,
      active: document.active,
      source: document.source,
      parentId: document.parentId,
      score: document.score,
      title: document.metadata?.title || document.externalKey,
      status: document.status,
      hasChild: document?.childrenCount > 0,
      childrenCount: document.childrenCount,
      currentPage: page,
      totalPages: 1 + Math.floor(document.childrenCount / pageSize),
      searching: false
    }
  })
}

export const linkDocumentsToAgent = async (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    documentIds: Array<string>
  }
) => {
  await linkContentToAgent(payload.agentId, payload.documentIds).catch(ex =>
    console.error('Error on link documents to agent', { ex, payload })
  )
}

export const bulkProcessLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    activate: boolean
    agentId: string
  }
) => {
  return bulkProcessAgentDocuments(
    payload.agentId,
    payload.ids,
    payload.activate
  ).catch(ex =>
    console.error('Error on bulk link document to agent', { ex, payload })
  )
}

export const bulkUnlinkAgentDocuments = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    ids: string[]
    agentId: string
  }
) => {
  return unlinkAgentDocuments(payload.agentId, payload.ids).catch(ex =>
    console.error('Error on bulk unlink document to agent', { ex, payload })
  )
}

export const enableLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    id: string
  }
) => {
  const { agentId, id } = payload
  return (
    id === 'kb'
      ? toggleLinkKBDocumentsAgent(agentId, true)
      : updateActiveLinkDocumentAgent(agentId, id, true)
  ).catch(ex =>
    console.error('Error on enable document link to agent', { ex, payload })
  )
}

export const disableLinkAgentDocument = (
  _: ActionContext<WozContentState, RootState>,
  payload: {
    agentId: string
    id: string
  }
) => {
  const { agentId, id } = payload
  return (
    id === 'kb'
      ? toggleLinkKBDocumentsAgent(agentId, false)
      : updateActiveLinkDocumentAgent(agentId, id, false)
  ).catch(ex =>
    console.error('Error on disable document link to agent', { ex, payload })
  )
}

export const searchContentGaps = async (
  context: ActionContext<WozContentState, RootState>,
  query: { search: string; page: number }
) => {
  try {
    context.commit(types.SET_CONTENT_GAPS_LIST_SEARCHING)

    const response = await getContentGaps(query.search, query.page)
    context.commit(types.SET_CONTENT_GAPS_LIST, response)
  } catch (err) {
    throw new Error('Error on get content gaps', { cause: err })
  }
}

export const clearContentGaps = (
  context: ActionContext<WozContentState, RootState>
) => {
  context.commit(types.CLEAR_CONTENT_GAPS_LIST)
}

export const markContentGapAsResolved = async (
  commit: ActionContext<WozContentState, RootState>,
  keys: [{ key: string; subKey: string }]
) => {
  try {
    await markAsResolved(keys)
  } catch (err) {
    throw new Error('Error on mark content gap as resolved', { cause: err })
  }
}
