import axiosRetry from 'axios-retry'

import { services } from '@octadesk-tech/services'

const API_CLIENTS = new Map()

export const getBaseClient = async (api, options = {}) => {
  if (API_CLIENTS.has(api)) {
    return API_CLIENTS.get(api)
  }

  const client = await services.createClient(api, options)

  axiosRetry(client, { retries: 3 })

  API_CLIENTS.set(api, client)

  return client
}

export const getAiAnalyticsApiClient = () => getBaseClient('aiAnalytics')
