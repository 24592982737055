import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('@/routes/PublicRoute.vue')
  },
  {
    path: '/public',
    name: 'Public',
    component: () => import('@/routes/PublicRoute.vue')
  },
  {
    path: '/private',
    name: 'Private',
    component: () => import('@/routes/PrivateRoute.vue')
  }
]

const router = new VueRouter({
  mode: 'abstract',
  routes
})

export default router
